<div class="mat-elevation-z4">

    <mat-card style="padding-bottom: 15px;">
        <mat-card-title>
            <i class="material-icons iconTitle">
                find_in_page
            </i>
            Denúncia Cadastrada
        </mat-card-title>
    </mat-card>

    <mat-divider></mat-divider>

    <mat-card>

        <form>

            <mat-form-field *ngIf="denuncia?.name">
                <mat-icon matPrefix class="material-icons iconInput">text_fields</mat-icon>
                <!-- Assim que coloca ícones em inputs -->
                <input matInput placeholder="Nome do Envolvido" [(ngModel)]="denuncia.name" id="name" name="name" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="denuncia?.category">
                <mat-icon matPrefix class="material-icons iconInput">category</mat-icon>
                <!-- Assim que coloca ícones em inputs -->
                <input matInput placeholder="Categoria" [(ngModel)]="denuncia.category" name="category" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="denuncia?.department">
                <mat-icon matPrefix class="material-icons iconInput">room</mat-icon>                
                <input matInput placeholder="Setor Envolvido" [(ngModel)]="denuncia.department" name="department" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="denuncia?.date">
                <mat-icon matPrefix class="material-icons iconInput">today</mat-icon>
                <!-- Assim que coloca ícones em inputs -->
                <input matInput placeholder="Data" [(ngModel)]="denuncia.date" name="date" disabled>
            </mat-form-field>

            <mat-form-field *ngIf="denuncia?.email">
                <mat-icon matPrefix class="material-icons iconInput">email</mat-icon>
                <!-- Assim que coloca ícones em inputs -->
                <input matInput placeholder="E-mail para Contato" [(ngModel)]="denuncia.email" name="email" type="email" disabled>
            </mat-form-field>

            <!--mat-form-field *ngIf="denuncia?.contact">
                <mat-icon matPrefix class="material-icons iconInput">call</mat-icon>
                < Assim que coloca ícones em inputs >
                <input matInput placeholder="Telefone para Contato" [(ngModel)]="denuncia.contact" mask="(00) 00000-0000" name="contact" type="tel" disabled>
            </mat-form-field-->

            <mat-form-field *ngIf="denuncia?.description">
                <mat-icon matPrefix class="material-icons iconInput">comment</mat-icon>
                <!-- Assim que coloca ícones em inputs -->
                <!-- <input matInput placeholder="Descrição" [(ngModel)]="denuncia.description" name="description" type="text" required> -->
                <textarea matInput placeholder="Descrição" [(ngModel)]="denuncia.description" name="description"
                    type="text" required cols="80" rows="8" disabled></textarea>
            </mat-form-field>

        </form>

    </mat-card>

</div>

<div class="container">
    <div class="btn-holder">
        <button mat-raised-button (click)="navigateToDenunciasCadastradas()" color="primary">
            <mat-icon matPrefix class="material-icons iconInput" style="align-items: baseline;">reply</mat-icon>
            Voltar para cadastradas
        </button>
    </div>
    <!--div class="btn-holder">
        <button mat-raised-button (click)="sendEmail()" style="background-color: royalblue;">
            Solicitar Informação
        </button>
    </div-->
    <div class="btn-holder" style="padding-top: 5px;">
        <button mat-raised-button (click)="navigateToDenunciaArquivada()" color="primary">
            <mat-icon matPrefix class="material-icons iconInput" style="align-items: baseline;">reply</mat-icon>
            Voltar para arquivadas
        </button>
    </div>
</div>