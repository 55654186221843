// consultadenuncia.component.ts
import { Component, OnInit } from '@angular/core';
import {DenunciaService} from './../denuncia.service';  


@Component({
  selector: 'app-consultadenuncia',
  templateUrl: './consultadenuncia.component.html',
  styleUrls: ['./consultadenuncia.component.css']
})
export class ConsultaDenunciaComponent {
  formConsulta = {
    'protocolo': ''
  };
  denuncia: any; // Objeto para armazenar os dados da denúncia

  constructor(private denunciaService: DenunciaService) {}

  buscarDenuncia() {
    this.denunciaService.readById(this.formConsulta.protocolo)
      .subscribe(
        (data: any) => {
          this.denuncia = data;
        },
        error => {
          // Trate erros conforme necessário
          console.error('Erro ao buscar denúncia:', error);
        }
      );
  }
}