import { Component, Input, OnInit } from '@angular/core';
import { DenunciaService } from './../denuncia.service';
import { Denuncia } from './../denuncia.model';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-denuncias-arquivadas',
  templateUrl: './denuncias-arquivadas.component.html',
  styleUrls: ['./denuncias-arquivadas.component.css']
})
export class DenunciasArquivadasComponent implements OnInit {
  
  constructor(private denunciaService: DenunciaService, private router: Router, private route: ActivatedRoute) { }
  
  denuncia: Denuncia;
  denuncias: Denuncia[]
  
  displayedColumns: string[] = ['id', 'name', 'category', 'department', 'date', 'email', 'contact', 'action']
  
  @Input('id')
  id: string;

  jsonLogin = {};
  ngOnInit(): void {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      this.router.navigate(['/login']);
    }
    this.denunciaService.readArquivada().subscribe(denuncias => {
      this.denuncias = denuncias
    })
  }
  
  navigateToDenunciaArquivada(): void {
    this.router.navigate(['/denuncias-arquivadas'])
  }
  
  navigateToDenuncia(): void {
    this.denunciaService.readById(this.id).subscribe(denuncia => {
      this.denuncia = denuncia;
    });
    this.router.navigate([`${"/denuncias/read"}/${this.id}`])
  }

  desarquivaDenuncia(denuncia: Denuncia): void {
    denuncia.arquivada = false;
    this.denunciaService.update(denuncia).subscribe();
    this.router.navigate(['/denuncia']);
  }
}
