<mat-card>

    <mat-card-title>
        <i class="material-icons iconTitle">
            delete_forever
        </i>
        Deseja excluir a denúncia?
    </mat-card-title>

    <mat-divider></mat-divider>

    <form>

        <mat-form-field *ngIf="denuncia?.name">
            <mat-icon matPrefix class="material-icons iconInput">text_fields</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Nome do Envolvido" [(ngModel)]="denuncia.name" name="name" disabled>
        </mat-form-field>
        
        <mat-form-field *ngIf="denuncia?.category">
            <mat-icon matPrefix class="material-icons iconInput">category</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Categoria" [(ngModel)]="denuncia.category" name="category" disabled>
        </mat-form-field>

        <mat-form-field *ngIf="denuncia?.department">
            <mat-icon matPrefix class="material-icons iconInput">room</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Setor Envolvido" [(ngModel)]="denuncia.department" name="department" disabled>
        </mat-form-field>

        <mat-form-field *ngIf="denuncia?.date">
            <mat-icon matPrefix class="material-icons iconInput">today</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Data" [(ngModel)]="denuncia.date" name="date" disabled>
        </mat-form-field>

        <mat-form-field *ngIf="denuncia?.email">
            <mat-icon matPrefix class="material-icons iconInput">email</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="E-mail para Contato" [(ngModel)]="denuncia.email" name="email" type="email" disabled>
        </mat-form-field>

        <mat-form-field *ngIf="denuncia?.contact">
            <mat-icon matPrefix class="material-icons iconInput">call</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Telefone para Contato" [(ngModel)]="denuncia.contact" name="contact" mask="(00) 00000-0000" type="tel" pattern="[0-9]{2} [0-9]{5}-[0-9]{4}" disabled>
        </mat-form-field>

        <mat-form-field *ngIf="denuncia?.description">
            <mat-icon matPrefix class="material-icons iconInput">comment</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <!-- <input matInput placeholder="Descrição" [(ngModel)]="denuncia.description" name="description" type="text" required> -->
            <textarea matInput placeholder="Descrição" [(ngModel)]="denuncia.description" name="description" type="text" required cols="80" rows="8" disabled></textarea>
        </mat-form-field>

    </form>

    <button mat-raised-button (click)="deleteDenuncia()" color="warn">
        Confirmar
    </button>

    <button mat-raised-button (click)="cancel()" color="accent">
        Cancelar
    </button>

</mat-card>