<mat-card class="home mat-elevation-z3">

    <mat-card-title class="title">
        <i class="material-icons iconTitle">
            help
        </i>
        DÚVIDAS - EXPLICAÇÕES OBJETIVAS SOBRE O CANAL DE DENÚNCIA:
    </mat-card-title>

</mat-card>

<mat-divider></mat-divider>

<mat-card>

    <mat-card-subtitle class="subtitle" style="text-align: justify;">
        <ul>

            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                1. O canal de denúncia se destina a recepcionar denúncias sobre irregularidades, atos ofensivos,
                atividades fora
                das normas da empresa cometidos por funcionários da organização.
            </li>

            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                2. Para maior efetividade, é muito importante que o denunciante, informe o e-mail de contato para futuro
                feedback da denúncia;
            </li>
            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                3. Ao final do registro da denúncia, será fornecido o número de protocolo da denúncia para eventual
                necessidade;
            </li>

            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                4. A denúncia será encaminhada ao setor responsável, que tem o prazo de até 15 (quinze) dias para
                apresentar as
                informações pertinentes (20 dias no caso de pedidos da Lei de Acesso à Informação).
            </li>

            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                5. O prazo para resposta da denúncia é de aproximadamente 30 (trinta) dias e possui um prazo de 7 (sete)
                dias
                para acréscimo de informações por parte do denunciante, caso seja necessário.
            </li>
            
            <li style="padding-bottom: 10px; list-style: none; padding-right: 40px;">
                6. Denúncias recebidas serão analisadas com muita atenção e seriedade. Por isso, é importante que o
                denunciante
                também assuma um compromisso com o Canal de Denúncia da empresa, de forma a se manifestar com veracidade
                e
                responsabilidade.
                Contamos com a sua contribuição para a prestação de um serviço célere, adequado, eficaz e, acima de
                tudo, justo.
                Informe o máximo de dados possível ao cadastrar sua denúncia, desta forma ela poderá ser analisada com
                mais
                eficiência.
            </li>

        </ul>
    </mat-card-subtitle>

</mat-card>