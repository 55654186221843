import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { LoginComponent } from './components/denuncia/login/login.component';
import { CadastroComponent } from './components/denuncia/cadastro/cadastro.component';
import { DenunciasArquivadasComponent } from './components/denuncia/denuncias-arquivadas/denuncias-arquivadas.component';
import { ConsultaDenunciaComponent } from './components/denuncia/consultadenuncia/consultadenuncia.component';
import { DenunciaCreateComponent } from './components/denuncia/denuncia-create/denuncia-create.component';
import { DenunciaDeleteComponent } from './components/denuncia/denuncia-delete/denuncia-delete.component';
import { DenunciaCrudComponent } from './views/denuncia-crud/denuncia-crud.component';
import { DenunciaUpdateComponent } from './components/denuncia/denuncia-update/denuncia-update.component';
import { DenunciaComponent } from './components/denuncia/denuncia/denuncia.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "cadastro",
    component: CadastroComponent
  },
  {
    path: "denuncia",
    component: DenunciaCrudComponent,
    canActivate: [AuthGuard] 
  },
  {
    path:"denuncia/cria-denuncia",
    component: DenunciaCreateComponent
  },
  {
    path: "denuncia/atualizar/:id",
    component: DenunciaUpdateComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: "denuncia/delete/:id",
    component: DenunciaDeleteComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: "denuncia/read/:id",
    component: DenunciaComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: "denuncias-arquivadas",
    component: DenunciasArquivadasComponent,
    canActivate: [AuthGuard] 
  },
  { 
    path: 'consulta-denuncia',
    component: ConsultaDenunciaComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard] // Forneça manualmente o AuthGuard
})
export class AppRoutingModule { }
