import { Component, OnInit, NgModule } from '@angular/core';
import { DenunciaService } from '../denuncia.service';
import { Denuncia } from '../denuncia.model';
import { Router } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

@Component({
  selector: 'app-denuncia-create',
  templateUrl: './denuncia-create.component.html',
  styleUrls: ['./denuncia-create.component.css'],
})

export class DenunciaCreateComponent implements OnInit {

 denuncia: Denuncia = {
    id: '',
    name: '',
    category: '',
    department: '',
    date: '',
    email: '',
    contact: '',
    description: '',
    arquivada: false
}
 
  constructor(private denunciaService: DenunciaService, private router: Router) { }

  ngOnInit(): void {    
  }

  generateId(): string {
      // var data = new Date(), anoAtual = data.getFullYear();
      // var base = anoAtual;
      // return base + Math.random().toString().substr(2, 6);
      return new Date().getTime().toString(16)
  }

  createDenuncia(): void {
    this.denuncia.id = this.generateId();
    this.denunciaService.create(this.denuncia).subscribe(() => {
      this.denunciaService.setCreatedDenunciaId(this.denuncia.id);
      this.denunciaService.showMessage('Denúncia criada!')
      this.router.navigate([''])
    })
  }

  cancel(): void {
    this.router.navigate(['/'])
  }

  reset(): void {
    this.denuncia.name = '';
    this.denuncia.category = '';
    this.denuncia.department = '';
    this.denuncia.date = '';
    this.denuncia.email = '';
    this.denuncia.contact = '';
    this.denuncia.description = '';
  }
}