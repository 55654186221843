import { Router } from '@angular/router';
import { DenunciaService } from '../denuncia.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Denuncia } from '../denuncia.model';
import { Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-denuncia-read',
  templateUrl: './denuncia-read.component.html',
  styleUrls: ['./denuncia-read.component.css']
})
export class DenunciaReadComponent implements OnInit {

  denuncias: Denuncia[]

  displayedColumns: string[] = ['id', 'name', 'category', 'department', 'date', 'email', 'action']

  constructor(private router: Router, private denunciaService: DenunciaService) {
  }

  ngOnInit(): void {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      this.router.navigate(['/login']);
    }
    this.denunciaService.read().subscribe(denuncias => {
      this.denuncias = denuncias
    })
  }

  navigateToDenunciaCreate(): void {
    this.router.navigate(['/denuncias/create'])
  }

  arquivaDenuncia(denuncia: Denuncia): void {
    denuncia.arquivada = true;
    this.denunciaService.update(denuncia).subscribe();
    this.router.navigate(['/denuncias-arquivadas']);
  }
}
