import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DenunciaService } from '../denuncia.service';
import { Denuncia } from '../denuncia.model';

@Component({
  selector: 'app-denuncia-delete',
  templateUrl: './denuncia-delete.component.html',
  styleUrls: ['./denuncia-delete.component.css']
})
export class DenunciaDeleteComponent implements OnInit {

  denuncia: Denuncia;

  constructor(
    private denunciaService: DenunciaService, 
    private router: Router,
    private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
      const authToken = localStorage.getItem('auth_token');
      if (!authToken) {
        this.router.navigate(['/login'])
      }
      const id = this.route.snapshot.params['id'];
      this.denunciaService.readById(id).subscribe(denuncia => {
        if (denuncia) {
          this.denuncia = denuncia;
        } else {
          console.error('Denuncia not found');
        }
      });
    }

  deleteDenuncia() {
    this.denunciaService.delete(this.denuncia.id).subscribe(() => {
      this.denunciaService.showMessage('Denúncia excluída com sucesso!');
      this.router.navigate(['/denuncia']);
    });

  };

  cancel(): void {
    this.router.navigate(['/denuncia']);
  };

}
