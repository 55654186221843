import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DenunciaService } from '../denuncia.service';
import { Denuncia } from '../denuncia.model';

@Component({
  selector: 'app-denuncia-update',
  templateUrl: './denuncia-update.component.html',
  styleUrls: ['./denuncia-update.component.css']
})
export class DenunciaUpdateComponent implements OnInit {

  denuncia: Denuncia;

  constructor(
    private denunciaService: DenunciaService, 
    private router: Router, 
    private route: ActivatedRoute
    ) {}

  jsonLogin = {};
  ngOnInit(): void {
    const authToken = localStorage.getItem('auth_token');
    if (!authToken) {
      this.jsonLogin = {};
      this.router.navigate(['/login'])
    }
    const id = +this.route.snapshot.paramMap.get('id');
    this.denunciaService.readById(id).subscribe(denuncia => {
      this.denuncia = denuncia;
    });
  }

  updateDenuncia(): void {
    this.denunciaService.update(this.denuncia).subscribe(() => {
      this.denunciaService.showMessage('Denúncia atualizada com sucesso!');
      this.router.navigate(['/denuncia']);
    });
  }

  cancel(): void {
    this.router.navigate(['/denuncia']);
  }

}
