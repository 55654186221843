<mat-card>

    <mat-card-title>
        <i class="material-icons iconTitle">
            record_voice_over
        </i>
        Nova Denúncia
    </mat-card-title>

    <mat-divider></mat-divider>

    <form>

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">text_fields</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>Seu Nome</mat-label>
            <input matInput placeholder="Ex.: João" [(ngModel)]="denuncia.name" name="name" required>
        </mat-form-field>

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">category</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>Categoria</mat-label>
            <input matInput placeholder="Ex.: Assédio" [(ngModel)]="denuncia.category" name="category" required>
        </mat-form-field>

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">room</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>Setor Envolvido (Opcional)</mat-label>
            <input matInput placeholder="Financeiro" [(ngModel)]="denuncia.department" name="department" >
        </mat-form-field>

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">today</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>Data</mat-label>
            <input matInput placeholder="Data" [(ngModel)]="denuncia.date" name="date" type="date" required>
        </mat-form-field>

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">email</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>E-mail para Contato (Opcional)</mat-label>
            <!-- <input matInput placeholder="E-mail para Contato (Opcional)" [(ngModel)]="denuncia.email" name="email" type="email pattern=".+@gmail.com"" > -->
            <input matInput placeholder="Ex.: maria@email.com" [(ngModel)]="denuncia.email" name="email" type="email" pattern=".+@teste.com" minlength="5" maxlength="64">
        </mat-form-field>

        <!--mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">call</mat-icon> 
            <mat-label>Telefone para Contato (Opcional)</mat-label-->
            <!-- <input matInput placeholder="Telefone para Contato (Opcional)" [(ngModel)]="denuncia.contact" name="contact" type="tel" pattern="[0-9]{2} [0-9]{5}-[0-9]{4}" maxlength="13"> -->
            <!--input matInput placeholder="Ex.: 61 98765-4321" [(ngModel)]="denuncia.contact" mask="(00) 00000-0000" name="contact" type="tel" pattern="[0-9]{2}[0-9]{5}[0-9]{4}" maxlength="16">
        </mat-form-field-->

        <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">comment</mat-icon>  <!-- Assim que coloca ícones em inputs -->
            <mat-label>Descrição</mat-label>
            <!-- <input matInput placeholder="Descrição" [(ngModel)]="denuncia.description" name="description" type="text" required> -->
            <textarea matInput placeholder="Insira seu texto aqui..." [(ngModel)]="denuncia.description" name="description" type="text" cols="80" rows="8" maxlength="5000" required></textarea>
        </mat-form-field>

        <!-- <mat-form-field>
            <mat-icon matPrefix class="material-icons iconInput">note_add</mat-icon>
            <input matInput placeholder="Anexos" [(ngModel)]="denuncia.anexo" name="anexo" type="file" ></input>
        </mat-form-field> -->

    </form>

    <div class="container">        
        <button mat-raised-button (click)="createDenuncia()" color="primary">
            Salvar
        </button>
        <button mat-raised-button (click)="cancel()" color="accent">
            Cancelar
        </button>
        <button mat-raised-button type="reset" (click)="reset()" color="warn" class="btn-holder">
            Limpar
        </button>
    </div>

</mat-card>