<div class="mat-elevation-z4">

    <mat-card style="padding-bottom: 15px;">

        <mat-card-title>
            <i class="material-icons iconTitle">
                pageview
            </i>
            Denúncias Arquivadas
        </mat-card-title>

    </mat-card>

    <mat-divider></mat-divider>

    <!-- <div>
        <mat-card>

            <mat-form-field appearance="outline">
                <mat-label>Número do protocolo</mat-label>
                <input matInput placeholder="Número do protocolo" [attr.id]="id?id:null" type="search" maxlength="10">
                <mat-icon matSuffix class="material-icons iconInput" (click)="navigateToDenuncia(id)"
                    style="display: flex; margin-right: 10px; font-size: 20px; color: #ffffff80; cursor: pointer;">
                    search</mat-icon> 
                <mat-hint>Obs.: O protoloco possui 10 números.</mat-hint>
            </mat-form-field>

        </mat-card>
    </div> -->

    <!-- <mat-divider></mat-divider> -->

    <table mat-table [dataSource]="denuncias" matSort>
        <!-- Id/Protocolo Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Protocolo</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.id }}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px" hidden>Protocolo</th>
            <td mat-cell *matCellDef="let denuncia" hidden>{{ denuncia.name }}</td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Categoria</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.category }}</td>
        </ng-container>

        <!-- Department Column -->
        <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Departamento</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.department }}</td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Data</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.date | date }}</td>
            <!-- <td mat-cell *matCellDef="let denuncia">{{ denuncia.date | date: 'medium' }}</td> -->
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">E-mail</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.email }}</td>
        </ng-container>

        <!-- Contact Column -->
        <ng-container matColumnDef="contact">
            <th mat-header-cell *matHeaderCellDef mat-sort-heade style="font-size: 15px">Contato</th>
            <td mat-cell *matCellDef="let denuncia">{{ denuncia.contact }}</td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef style="font-size: 15px; text-align: center;">Ações</th>
            <td mat-cell *matCellDef="let denuncia" style="text-align: center;">
                <a routerLink="/denuncia/read/{{ denuncia.id }}" class="read">
                    <i class="material-icons">
                        visibility
                    </i>
                </a>
                <button (click)="desarquivaDenuncia(denuncia)" class="arquivar">
                    <i class="material-icons">unarchive</i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let denuncia; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator #paginator length="denuncias.length" [pageSize]="10" [pageSizeOptions]="[1, 5, 15, 20, 25]"
        showFirstLastButtons>
    </mat-paginator>

</div>

<!-- <div style="margin-top: 5px;">
    <mat-divider></mat-divider>

    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>

    <mat-divider></mat-divider>
    <mat-divider></mat-divider>

    <mat-spinner color="accent"></mat-spinner>

    <mat-divider></mat-divider>
    <mat-divider></mat-divider>

    <mat-progress-bar mode="buffer" color="primary"></mat-progress-bar>

    <mat-divider></mat-divider>
    <mat-divider></mat-divider>

    <mat-spinner color="warn"></mat-spinner>

    <mat-divider></mat-divider>
    <mat-divider></mat-divider>

    <mat-progress-bar mode="query" color="warn"></mat-progress-bar>

    <mat-divider></mat-divider>
</div> -->