<mat-card class="home mat-elevation-z3">

    <mat-card-title class="title" style="padding-left: 15px;">
        CANAL DE DENÚNCIA
    </mat-card-title>

</mat-card>

<mat-divider></mat-divider>

<mat-card>

    <mat-card-subtitle class="subtitle" style="text-align: justify; padding-right: 40px; padding-left: 40px; padding-bottom: 10px;">
        Sua voz importa!!
    </mat-card-subtitle>
    <mat-card-subtitle class="textCard" style="text-align: justify; padding-right: 50px; padding-left: 50px; padding-bottom: 5px;">
        Aqui você pode denunciar acerca de irregularidades, desvios de conduta de colaboradores e gestores da organização, sem medo de represálias.
        Não se preocupe, sua identidade será mantida em sigilo.
        Faça a diferença, denuncie.
    </mat-card-subtitle>
    

</mat-card>
<mat-card *ngIf="createdDenunciaId">
    <div style="text-align: center; padding: 20px;">
      <mat-card-subtitle class="textCard" style="font-size: 1.5em;">
        Seu protocolo: {{ createdDenunciaId }}
      </mat-card-subtitle>
    </div>
</mat-card>