<mat-sidenav-container class="container">
    <mat-sidenav class="sidenav" mode="side" opened fixedInViewport="true" fixedTopGap="64">
        <mat-nav-list class="nav-list .color_font_dark">
            <a mat-list-item routerLink="/">
                <i class="material-icons">home</i>
                Início
            </a>
            <ng-container *ngIf="isAuthenticated()">
                <a mat-list-item routerLink="/denuncia">
                    <i class="material-icons">topic</i>
                    Denúncias
                </a>
                <a mat-list-item routerLink="/denuncias-arquivadas">
                    <i class="material-icons">archive</i>
                    Denúncias Arquivadas
                </a>
            </ng-container>
            <a mat-list-item routerLink="/consulta-denuncia">
                <i class="material-icons">search</i>
                Consultar Denúncia
            </a>
            <a mat-list-item routerLink="denuncia/cria-denuncia">
                <i class="material-icons">add</i>
                Criar Denúncia
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
