import { HeaderService } from './../../components/template/header/header.service';
import { DenunciaService } from './../../components/denuncia/denuncia.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  createdDenunciaId: string;

  constructor(
    private headerService: HeaderService,
    private denunciaService: DenunciaService
  ) {
    headerService.headerData = {
      title: 'CANAL DE DENÚNCIA',
      icon: '',
      routeUrl: ''
    };
  }

  ngOnInit(): void {
    if (this.denunciaService.getCreatedDenunciaId()) {
      this.createdDenunciaId = this.denunciaService.getCreatedDenunciaId();

      // Após obter o ID, resete-o para que não seja exibido novamente
      this.denunciaService.setCreatedDenunciaId(null);
    }
  }
}