import {Component, Inject, OnInit} from '@angular/core';
import {DenunciaService} from './../denuncia.service';
import {Denuncia} from './../denuncia.model';
import {Router, ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface formEmail {
    'email': any,
    'assunto': any,
    'descricao': any,
    'functionEmail': any

    funcao(): void;
}

@Component({
    selector: 'app-denuncia',
    templateUrl: './denuncia.component.html',
    styleUrls: ['./denuncia.component.css']
})

export class DenunciaComponent implements OnInit {

    constructor(private denunciaService: DenunciaService, private router: Router,
                private route: ActivatedRoute, public dialog: MatDialog) {
    }

    denuncia: Denuncia;
    email = '';
    descricao = '';
    assunto = '';
    funcao = null;

    ngOnInit(): void {
        this.funcao = this.funcaodale;
        const authToken = localStorage.getItem('auth_token');
        if (!authToken) {
            this.router.navigate(['/login'])
        }
        const id: String = this.route.snapshot.paramMap.get('id');
        this.denunciaService.readById(id).subscribe(denuncia => {
            this.denuncia = denuncia;
        });
    }

    funcaodale() {
        try {
            let formEmail = {
                'email': this.email,
                'descricao': this.descricao,
                'assunto': this.assunto
            }
            this.denunciaService.sendEmail(formEmail).subscribe(data => {
            })
        } catch (e) {
            alert("Falha no envio do e-mail.")
        }

    }

    navigateToDenunciasCadastradas(): void {
        this.router.navigate(['/denuncia'])
    }

    navigateToDenunciaArquivada(): void {
        this.router.navigate(['/denuncias-arquivadas'])
    }

    sendEmail() {
        const instance = this;
        const dialogRef = this.dialog.open(DialogContentEmail, {
            data: {assunto: instance.assunto, descricao: instance.descricao,
                funcao: instance.funcao, email: this.denuncia.email, denunciaService: this.denunciaService}
        });
        //dialogRef. = 'bla';

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}


@Component({
    selector: 'dialog-content-email',
    templateUrl: 'dialog-content-email.html',
    styleUrls: ['dialog-content-email.css'],
})

export class DialogContentEmail {
    constructor(
        public dialogRef: MatDialogRef<DenunciaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: formEmail) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}