<mat-card>
  <mat-card-title>
    <i class="material-icons iconTitle">create</i> Consultar Denúncia
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content class="custom-mat-form-field">
    <mat-form-field appearance="fill" class="custom-mat-form-field">
      <mat-label>Informe o protocolo da Denúncia</mat-label>
      <input matInput placeholder="ID da Denúncia" [(ngModel)]="formConsulta.protocolo" />
    </mat-form-field>

    <button mat-raised-button color="primary" (click)="buscarDenuncia()">Buscar</button>
  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card>

    <form>
      <mat-form-field *ngIf="denuncia">
        <mat-icon matPrefix class="material-icons iconInput">text_fields</mat-icon>
        <!-- Assim que coloca ícones em inputs -->
        <input matInput placeholder="Protocolo" [(ngModel)]="denuncia.id" id="id" name="id" disabled>
      </mat-form-field>
        <mat-form-field *ngIf="denuncia">
            <mat-icon matPrefix class="material-icons iconInput">fingerprint</mat-icon>
            <!-- Assim que coloca ícones em inputs -->
            <input matInput placeholder="Nome do Envolvido" [(ngModel)]="denuncia.name" id="name" name="name" disabled>
        </mat-form-field>
        
        <mat-form-field *ngIf="denuncia">
          <mat-icon matPrefix class="material-icons iconInput">category</mat-icon>
          <!-- Assim que coloca ícones em inputs -->
          <input matInput placeholder="Categoria" [(ngModel)]="denuncia.category" id="category" name="category" disabled>
      </mat-form-field>
      <mat-form-field *ngIf="denuncia">
        <mat-icon matPrefix class="material-icons iconInput">today</mat-icon>
        <!-- Assim que coloca ícones em inputs -->
        <input matInput placeholder="Data Cadastro" [(ngModel)]="denuncia.date" id="date" name="date" disabled>
      </mat-form-field>
      <mat-form-field *ngIf="denuncia">
        <mat-icon matPrefix class="material-icons iconInput">visibility</mat-icon>
        <!-- Assim que coloca ícones em inputs -->
        <input matInput placeholder="Status Denúncia" [value]="denuncia.statusDenuncia ? 'Ativo' : 'Fechada'" id="statusDenuncia" name="statusDenuncia" disabled>
      </mat-form-field>
      
      <mat-form-field *ngIf="denuncia">
        <mat-icon matPrefix class="material-icons iconInput">info</mat-icon>
        <!-- Assim que coloca ícones em inputs -->
        <input matInput placeholder="Arquivada?" [value]="denuncia.arquivada ? 'Arquivada' : 'Aberta'" id="arquivada" name="arquivada" disabled>
      </mat-form-field>
      

    </form>

</mat-card>



</mat-card>