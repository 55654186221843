<!-- <div>
  <button mat-raised-button (click)="navigateToDenunciaCreate()" color="primary">
    <mat-icon matPrefix class="material-icons iconInput">add</mat-icon>
    Novo Produto
  </button>
</div> -->

<div class="mat-elevation-z4">

  <mat-card>
    <mat-card-title>
      <i class="material-icons iconTitle">
        text_snippet
      </i>
      Denúncias Cadastradas
    </mat-card-title>
  </mat-card>

  <mat-divider></mat-divider>

  <table mat-table [dataSource]="denuncias" matSort>
    <!-- Id/Protocolo Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Protocolo</th>
      <td mat-cell *matCellDef="let denuncia">{{ denuncia.id }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px" hidden>Protocolo</th>
      <td mat-cell *matCellDef="let denuncia" hidden>{{ denuncia.name }}</td>
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Categoria</th>
      <td mat-cell *matCellDef="let denuncia">{{ denuncia.category }}</td>
    </ng-container>

    <!-- Department Column -->
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Departamento</th>
      <td mat-cell *matCellDef="let denuncia">{{ denuncia.department }}</td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">Data</th>
      <td mat-cell *matCellDef="let denuncia">{{ denuncia.date | date }}</td>
      <!-- <td mat-cell *matCellDef="let denuncia">{{ denuncia.date | date: 'medium' }}</td> -->
    </ng-container>

    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="font-size: 15px">E-mail</th>
      <td mat-cell *matCellDef="let denuncia">{{ denuncia.email }}</td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="font-size: 15px; text-align: center;">Ações</th>
      <td mat-cell *matCellDef="let denuncia" style="text-align: center;">
        <a routerLink="/denuncia/read/{{ denuncia.id }}" class="read">
          <i class="material-icons">
            visibility
          </i>
        </a>
        <button (click)="arquivaDenuncia(denuncia)" class="arquivar">
          <i class="material-icons">archive</i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let denuncia; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator #paginator length="denuncias.length" [pageSize]="10"
    [pageSizeOptions]="[1, 5, 15, 20, 25]" showFirstLastButtons>
  </mat-paginator>

</div>

<!-- <div class="container">
  <div class="btn-holder">

    <button mat-raised-button (click)="navigateToDenunciaCreate()" color="primary">
      <mat-icon matPrefix class="material-icons iconInput" style="align-items: baseline;">add</mat-icon>
      Nova Denúncia
    </button>
    
  </div>
</div> -->