<mat-card class="home mat-elevation-z3">

    <mat-card-title class="title" style="padding-left: 15px;">
        *Cadastro somente aos usuários do setor responsável pelas denúncias cadastradas neste sistema.*
    </mat-card-title>

</mat-card>

<body>
    <div class="container">
        <div class="container-box">
            <span style="text-align: justify;">Preencha as informações de cadastro do usuário, 
                somente com o e-mail e senha cadastrados aqui que os usuários poderão ter acesso ao sistema.</span>
        </div>
        <div class="box">
            <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="Digite seu e-mail ou usuário"
                       [(ngModel)]='formLogin.email'>
                <mat-hint>E-mail deve ser institucional.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5px;">
                <mat-label>Senha</mat-label>
                <input matInput placeholder="Digite sua senha"
                       [(ngModel)]='formLogin.pass' [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                    [attr.aria-pressed]="hide">
                    <i class="material-icons">{{hide ? 'visibility_off' : 'visibility'}}</i>
                </button>
                <mat-hint>Senha deve conter de 6 a 8 caracteres.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5px;">
                <mat-label>Confirmar Senha</mat-label>
                <input matInput placeholder="Confirme sua senha"
                       [(ngModel)]='formLogin.repass' [type]="hide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="hide = !hide">
                    <i class="material-icons">{{hide ? 'visibility_off' : 'visibility'}}</i>
                </button>
                <mat-hint>Sua senha precisa ser igual.</mat-hint>
            </mat-form-field>
            
            <button class="cadastrar" mat-button (click)="authLogin()">
                Cadastrar
                <i class="material-icons" style="margin-bottom: 2px;">
                  arrow_forward_ios
                </i>
              </button>
        </div>
    </div>
</body>