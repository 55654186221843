import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { HeaderService } from '../header/header.service'; 

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  token: string | null;

  constructor(public dialog: MatDialog, private headerService: HeaderService) { }

  ngOnInit(): void {
    this.loadToken();
    this.headerService.authTokenObservable.subscribe(() => {
      this.isAuthenticated()
    });
  }


  private loadToken(): void {
    this.token = localStorage.getItem('auth_token');
  }

  isAuthenticated(): boolean {
    return !!localStorage.getItem('auth_token');
  }
}
