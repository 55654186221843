import { HeaderService } from '../../components/template/header/header.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-denuncia-crud',
  templateUrl: './denuncia-crud.component.html',
  styleUrls: ['./denuncia-crud.component.css']
})
export class DenunciaCrudComponent implements OnInit {

  constructor(
    private headerService: HeaderService
    ) {
    headerService.headerData = {
      title: 'CANAL DE DENÚNCIA',
      icon: '',
      routeUrl: '/denuncia'
    }

    headerService.headerData = {
      title: 'CANAL DE DENÚNCIA',
      icon: '',
      routeUrl: '/denuncias-arquivadas'
    }

    headerService.headerData = {
      title: 'CANAL DE DENÚNCIA',
      icon: '',
      routeUrl: '/duvidas'
    }
   }

  ngOnInit(): void {
  }
  
  get title(): string {
    return this.headerService.headerData.title
  }

  get icon(): string {
    return this.headerService.headerData.icon
  }

  get routeUrl(): string {
    return this.headerService.headerData.routeUrl
  }
}
