import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/template/header/header.component';
import { FooterComponent } from './components/template/footer/footer.component';
import { NavComponent } from './components/template/nav/nav.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { RedDirective } from './directives/red.directive';
import { HomeComponent } from './views/home/home.component';
import { DenunciaCrudComponent } from './views/denuncia-crud/denuncia-crud.component';
import { DenunciaUpdateComponent } from './components/denuncia/denuncia-update/denuncia-update.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule} from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { DenunciasArquivadasComponent } from './components/denuncia/denuncias-arquivadas/denuncias-arquivadas.component';
import { DenunciaComponent } from './components/denuncia/denuncia/denuncia.component';
import { DialogContentEmail } from './components/denuncia/denuncia/denuncia.component';
import { DuvidasComponent } from './components/denuncia/duvidas/duvidas.component';
import { LoginComponent } from './components/denuncia/login/login.component';
import { CadastroComponent } from './components/denuncia/cadastro/cadastro.component';
import { ConsultaDenunciaComponent } from './components/denuncia/consultadenuncia/consultadenuncia.component';
import { DenunciaDeleteComponent } from './components/denuncia/denuncia-delete/denuncia-delete.component';
import { DenunciaReadComponent } from './components/denuncia/denuncia-read/denuncia-read.component';
import { DenunciaCreateComponent } from './components/denuncia/denuncia-create/denuncia-create.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './guards/authInterceptor';

registerLocaleData(localePt);

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NavComponent,
    HomeComponent,
    DenunciaCrudComponent,
    RedDirective,
    DenunciaCreateComponent,
    DenunciaReadComponent,
    DenunciaUpdateComponent,
    DenunciaDeleteComponent,
    DenunciasArquivadasComponent,
    DenunciaComponent,
    DialogContentEmail,
    DuvidasComponent,
    LoginComponent,
    CadastroComponent,
    ConsultaDenunciaComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatSnackBarModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxMaskModule.forRoot(maskConfig)
  ],
  providers: [
    AuthGuard,
    {
    provide: LOCALE_ID,
    useValue: 'pt-BR'
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
