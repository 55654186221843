// header.component.ts
import { Component, OnInit } from '@angular/core';
import { HeaderService } from './header.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(public router: Router, public headerService: HeaderService) { }

  isAuthenticated: boolean = false;

  ngOnInit(): void {
    this.headerService.authTokenObservable.subscribe(token => {
      this.isAuthenticated = !!token;
    });
  }

  get title(): string {
    return this.headerService.headerData.title;
  }

  get icon(): string {
    return this.headerService.headerData.icon;
  }

  get routeUrl(): string {
    return this.headerService.headerData.routeUrl;
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated;
  }

  navigateToLogin(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    localStorage.removeItem('auth_token');
    location.reload();
  }
}
