<mat-toolbar class="header mat-elevation-z4">
    <span>
        <a routerLink="/">
            <img class="logo" src="assets/img/logo.png" alt="logo">
        </a>
    </span>
    <span class="title-group">
        <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
        <a routerLink="{{ routeUrl }}">
            {{ title }}
        </a>
        <mat-progress-bar mode="query" color="accent"></mat-progress-bar>
    </span>
    <div *ngIf="!isLoggedIn()" class="login-cadastro">
        <span>
            <button mat-button (click)="navigateToLogin()">
                Login
                <i class="material-icons">login</i>
            </button>            
        </span>
    </div>
    <div *ngIf="isLoggedIn()" class="login-cadastro">
        <span>
            <i class="material-icons" style="vertical-align: middle;">how_to_reg</i>
            <label style="margin-left: 16px; border-right: 2px solid white;"></label>
            <button mat-button (click)="logout()">
                Logout
                <i class="material-icons">login</i>
            </button>
        </span>
    </div>
</mat-toolbar>
