import { BehaviorSubject, Observable } from 'rxjs';
import { HeaderData } from './header-data.model';
import { EventEmitter, Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private _headerData = new BehaviorSubject<HeaderData>({
    title: 'CANAL DE DENÚNCIA',
    icon: '',
    routeUrl: ''
  });

  private _authToken = new BehaviorSubject<string | null>(localStorage.getItem('auth_token'));

  constructor() { }

  get headerData(): HeaderData {
    return this._headerData.value;
  }

  set headerData(headerData: HeaderData) {
    this._headerData.next(headerData);
  }

  get authToken(): string | null {
    return this._authToken.value;
  }

  set authToken(token: string | null) {
    localStorage.setItem('auth_token', token);
    this._authToken.next(token);
  }

  get authTokenObservable(): Observable<string | null> {
    return this._authToken.asObservable();
  }

}
