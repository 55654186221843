import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Injectable, enableProdMode } from '@angular/core';
import { Denuncia } from './denuncia.model';
import { Observable, EMPTY, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

enableProdMode();

@Injectable({
  providedIn: 'root'
})
export class DenunciaService {

  private createdDenunciaId: string;
  private tokenKey = 'auth_token';
  private url = 'https://backendcanaldenuncias.azurewebsites.net';

  private userLoggedInSubject = new Subject<void>(); 

  userLoggedIn$ = this.userLoggedInSubject.asObservable();

  emitUserLoggedIn(): void { 
    this.userLoggedInSubject.next(); 
  }

  private loginStatusSubject = new BehaviorSubject<boolean>(false);

  loginStatus$ = this.loginStatusSubject.asObservable();

  getDenuncia = `${this.url}/getDenuncia`;
  getDenunciaById = `${this.url}/getDenunciaById`;
  getDenunciaArquivada = `${this.url}/getDenunciaArquivada`;
  createDenuncia = `${this.url}/createDenuncia`;
  editDenuncia = `${this.url}/editDenuncia`;
  deleteDenuncia = `${this.url}/deleteDenuncia`;

  // Depois deve-se mudar para outro arquivo.
  getUsuarioByEmail = `${this.url}/getUsuarioByEmail`;
  createUsuarioUrl = `${this.url}/createUsuario`;
  doLoginUrl = `${this.url}/doLogin`;
  sendEmailUrl = `${this.url}/sendEmail`;

  constructor(
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router
    ) {}

  // Cria uma denuncia
  create(denuncia: Denuncia): Observable<Denuncia> {
    return this.http.post<Denuncia>(this.createDenuncia, denuncia).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  };

  // Obtem todos as denuncias
  /*read(): Observable<Denuncia[]> {
    return this.http.get<Denuncia[]>(this.getDenuncia).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }*/
  read(): Observable<Denuncia[]> {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', token);
    }
  
    return this.http.get<Denuncia[]>(this.getDenuncia, { headers }).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
    );
  }

  // Obtem todos as denuncias
  readArquivada(): Observable<Denuncia[]> {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', token);
    }
    return this.http.get<Denuncia[]>(this.getDenunciaArquivada, { headers }).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Obtem uma denuncia pelo id
  readById(id): Observable<Denuncia> {
    const url = `${this.getDenunciaById}/${id}`;
    return this.http.get<Denuncia>(url).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Atualiza uma denuncia
  update(denuncia: Denuncia): Observable<Denuncia> {

    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', token);
    }

    const url = `${this.editDenuncia}/${denuncia.id}`;
    return this.http.put<Denuncia>(url, denuncia, { headers }).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Deleta uma denuncia
  delete(id): Observable<Denuncia> {
    let headers = new HttpHeaders();
    const token = this.getToken();
    if (token) {
      headers = headers.set('Authorization', token);
    }

    const url = `${this.deleteDenuncia}/${id}`;
    return this.http.delete<Denuncia>(url, {headers}).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Arquiva Denuncia
  updateArquivada(denuncia: Denuncia): Observable<Denuncia> {
    const url = `${this.editDenuncia}/${denuncia.id}`;
    return this.http.put<Denuncia>(url, denuncia).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Desarquiva Denuncia
  updateDesarquivada(denuncia: Denuncia): Observable<Denuncia> {
    const url = `${this.editDenuncia}/${denuncia.id}`;
    return this.http.put<Denuncia>(url, denuncia).pipe(
      map(obj => obj),
      catchError(e => this.errorHandler(e))
      );
  }

  // Não deveria estar aqui, checa usuários por e-mail.
  readByEmail(formLogin): Observable<any> {
    const url = `${this.getUsuarioByEmail}`;
    return this.http.post<any>(url, formLogin).pipe(
        map(obj => obj),
        catchError(e => this.errorHandler(e))
    );
  }

  // Não deveria estar aqui.
  createUsuario(formLogin): Observable<any> {
    const url = `${this.createUsuarioUrl}`;
    return this.http.post<any>(url, formLogin).pipe(
        map(obj => obj),
        catchError(e => this.errorHandler(e))
    );
  }
  // Não deveria estar aqui.
  /*doLogin(formLogin): Observable<any> {
    const url = `${this.doLoginUrl}`;
    return this.http.post<any>(url, formLogin).pipe(
        map(obj => obj),
        catchError(e => this.errorHandler(e))
    );
  }*/
  private handleError(error: any): Observable<never> {
    // Trate o erro conforme necessário, aqui estou apenas exibindo uma mensagem de erro
    this.snackBar.open('Ocorreu um erro ao processar a solicitação.', 'Fechar', {
      duration: 3000,
    });
    return EMPTY;
  }

  doLogin(formLogin): Observable<any> {
    return this.http.post<any>(this.doLoginUrl, formLogin).pipe(
      tap(response => {
        if (response.token) {
          localStorage.setItem(this.tokenKey, response.token);
          this.loginStatusSubject.next(true);
        }
      }),
      catchError(this.handleError)
    );
  }

  logout(){
    localStorage.removeItem('auth_token');
    location.reload();
      this.router.navigate(['/login']);

  }

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }
  
  showMessage(msg: string, isError: boolean = false): void {
    this.snackBar.open(msg, 'X', {
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: isError ? ['msg-error'] : ['msg-success']
    });
  }

  errorHandler(e: any): Observable<any> {
    this.showMessage('Ocorreu um erro!', true);
    return EMPTY;
  }

  // Não deveria estar aqui.
  sendEmail(formEmail): Observable<any> {
    const url = `${this.sendEmailUrl}`;
    return this.http.post<any>(url, formEmail).pipe(
        map(obj => obj),
        catchError(e => this.errorHandler(e))
    );
  }
  setCreatedDenunciaId(id: string): void {
    this.createdDenunciaId = id;
  }

  getCreatedDenunciaId(): string {
    return this.createdDenunciaId;
  }

}
