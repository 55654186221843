import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {DenunciaService} from "../denuncia.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./cadastro.component.css']
})
export class CadastroComponent implements OnInit {
  hide = true;
  hide1 = true;
  constructor(private router: Router, private denunciaService: DenunciaService, private _snackBar: MatSnackBar) {}
  

  formLogin = {
    email: '',
    pass: '',
    repass: ''
  }

  jsonLogin = {}
  ngOnInit(): void {
  const authToken = localStorage.getItem('auth_token');
  if (!authToken) {
      this.jsonLogin = {};
      this.router.navigate(['/login'])
    }
  }

  authLogin(): void {
    try {
      // Verificar se senhas são iguais e campos não estão vazios
      if (this.formLogin.pass === this.formLogin.repass && this.formLogin.email && this.formLogin.pass && this.formLogin.repass) {
        // Chamar serviço para criar usuário
        this.denunciaService.createUsuario(this.formLogin).subscribe(data => {
          // Exibir mensagem de sucesso
          this._snackBar.open('Cadastro realizado com sucesso!', 'Fechar', {
            duration: 3000,
          });
        });
      } else {
        // Senha diferente ou campos vazios, exibir mensagem de erro
        this._snackBar.open('Não foi possível cadastrar devido a dados inválidos.', 'Fechar', {
          duration: 5000,
        });
      }
    } catch (e) {
      console.error(e);
      // Exibir mensagem de erro
      this._snackBar.open('Ocorreu um erro ao cadastrar.', 'Fechar', {
        duration: 5000,
      });
    }
  }

  navigateToHome(): void {
    this.router.navigate(['/login'])
  }

}