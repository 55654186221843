import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DenunciaService } from "../denuncia.service";
import { HeaderService } from '../../template/header/header.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  hide = true;
  mensagemLogin = 'Não compartilhe suas informações de acesso com ninguém!';
  formLogin = {
    email: '',
    pass: ''
  };

  constructor(private router: Router, private denunciaService: DenunciaService, private headerService: HeaderService) { }

  ngOnInit(): void {}

  doLogin(): void {
    try {
      this.denunciaService.doLogin(this.formLogin).subscribe(data => {
        if (data.token) {
          // Armazenar o token de autenticação no localStorage
          localStorage.setItem('auth_token', data.token);
          
          this.headerService.authToken = data.token;
          // Redirecionar para a página inicial após o login
          this.navigateToHome();
        } else {
          this.mensagemLogin = 'Credenciais inválidas!';
        }
      }, error => {
        this.mensagemLogin = 'Erro ao fazer login. Por favor, verique o login e a senha.';
      });
    } catch (e) {
      this.mensagemLogin = 'Erro inesperado. Por favor, tente novamente mais tarde.';
    }
  }

  navigateToHome(): void {
    this.router.navigate(['/']);
  }
}
